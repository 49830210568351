<ng-container *ngIf="!isNavigating">
	<div class="login-brand text-center">
		<img src="/assets/img/rightsline-logo_prpl_180w.png" />
	</div>

	<ng-container [ngSwitch]="currentStep">
		<rl-login-step [stepTitle]="'Welcome back.'" [errorMessage]="errorMessage" [showAltRegions]="showAltRegions"
			(altRegionSelect)="altRegionSelected($event)" *ngSwitchCase="'login-options'">
			<form (ngSubmit)="submitEmail($event)">
				<div class="mb-2" hasError>
					<label class="form-control-label">Email</label>
					<input type="text" class="form-control" name="email" [formControl]="email" [rlAutoFocus] />
				</div>
				<div class="w-100 d-flex justify-content-end mt-4">
					<button type="submit" class="btn btn-success" [disabled]="!loginForm.valid" rlBusyButton>
						Next
					</button>
				</div>
			</form>
		</rl-login-step>
		<rl-login-step [stepTitle]="'Welcome back.'" [errorMessage]="errorMessage" [showAltRegions]="showAltRegions"
			(altRegionSelect)="altRegionSelected($event)" *ngSwitchCase="'enter-password'">
			<form (ngSubmit)="submitPassword($event)">
				<div class="mb-3" hasError>
					<label class="form-control-label">Email</label>
					<input type="text" class="form-control" name="email" [formControl]="email" />
				</div>
				<div class="mb-2" hasError *ngIf="loginOptions.showPassword">
					<label class="form-control-label mb-1">Password</label>
					<input type="password" class="form-control" name="password" [formControl]="password"
						[rlAutoFocus] />
				</div>
				<div class="d-flex justify-content-between align-items-center mt-4">
					<a *ngIf="loginOptions.showPassword" routerLink="/forgot-password"
						[state]="{ email: email.value }">Forgot your password?</a>
					<div class="text-end">
						<span *ngIf="loginOptions.showPassword">
							<button type="submit" class="btn btn-success" [disabled]="!loginForm.valid" rlBusyButton>
								Login
							</button>
						</span>
						<rl-sso-login-button [loginOptions]="loginOptions"></rl-sso-login-button>
					</div>
				</div>
			</form>
		</rl-login-step>
		<rl-login-step [stepTitle]="'Verify Two Factor Auth'" [errorMessage]="errorMessage"
			*ngSwitchCase="'two-factor'">
			<form (ngSubmit)="submitTwoFactor($event)">
				<div class="mb-2" hasError>
					<label class="form-control-label">Enter Your Confirmation Code</label>
					<input type="text" class="form-control" name="two-factor-token" [formControl]="twoFactorToken"
						[rlAutoFocus] />
				</div>
				<div class="mb-2">
					<label class="form-control-label">
						<input type="checkbox" [formControl]="rememberDevice" class="form-check-input" />
						<span class="ms-2">Remember this device?</span>
					</label>
				</div>
				<div class="d-flex justify-content-between align-items-center mt-4">
					<button type="submit" class="btn btn-success" [disabled]="!loginForm.valid" rlBusyButton>
						Verify
					</button>
				</div>
			</form>
		</rl-login-step>
		<rl-login-step [stepTitle]="'Choose Application'" [errorMessage]="errorMessage" *ngSwitchCase="'choose-app'">
			<rl-select-div-control [apps]="chooseApps" [disabled]="chooseApp.disabled"
				[(ngModel)]="typeAheadSelectedApp" (ngModelChange)="typeAheadChooseDiv()">
			</rl-select-div-control>
			<div class="mb-2">
				<select class="form-select" [formControl]="chooseApp" (ngModelChange)="submitWithApp()" [rlAutoFocus]>
					<option [ngValue]="null">Choose an Application</option>
					<option *ngFor="let app of chooseApps" [ngValue]="app"
						[innerText]="app.name + ' -- Div '+ app.divId">
					</option>
				</select>
			</div>
			<rl-recent-divs (onDivSelect)="chooseRecentApp($event)" [disabled]="chooseApp.disabled"></rl-recent-divs>
		</rl-login-step>
		<rl-login-step [stepTitle]="'Maintenance'" [errorMessage]="errorMessage" *ngSwitchCase="'maintenance'">
			<rl-maintenance rlKonami (onBypass)="bypassMaintenance()"></rl-maintenance>
		</rl-login-step>
	</ng-container>
</ng-container>