<div *ngIf="versionInfo.Message?.length > 0 && !isLocal" class="alert alert-warning" role="alert">
	{{ versionInfo.Message }}
</div>

<div rlKonami [combo]="'ux2Link'" (onEntered)="toggleUx2Links()" class="card">
	<div *ngIf="!isLocal; else runningLocally" class="card-body">
		<h5 class="card-title">Application Version</h5>
		<table class="table table-striped m-0">
			<tbody>
				<tr>
					<th>Branch</th>
					<td>
						<ng-container *ngIf="hasBranch">
							<a target="_blank" [href]="branchUrl">{{ branch }}</a>
						</ng-container>
						<ng-container *ngIf="!hasBranch">
							<span class="text-muted">{{branch ?? 'unknown'}}</span>
						</ng-container>
					</td>
				</tr>
				<tr>
					<th>Release Name</th>
					<td><a target="_blank" [href]="releaseUrl">{{ releaseName }}</a></td>
				</tr>
				<tr>
					<th>Current SHA</th>
					<td><a target="_blank" [href]="currentShaUrl" [class.text-danger]="apiVersionMismatch">{{ currentSha }}</a></td>
				</tr>
				<tr>
					<th>Previous SHA</th>
					<td><a target="_blank" [href]="previousShaUrl">{{ previousSha }}</a></td>
				</tr>
				<tr>
					<th>App Api Version</th>
					<td [class.text-danger]="apiVersionMismatch">{{ apiVersion }}</td>
				</tr>
				<tr>
					<th>Build Time</th>
					<td>
						<span *ngIf="buildDate.isValid()" [ngbTooltip]="buildDate.fromNow()">
							{{ buildDate.format("MMMM Do YYYY, h:mm:ss a") }} ({{buildDate.fromNow()}})
						</span>
						<span *ngIf="!buildDate.isValid()">
							{{ versionInfo.UtcDateBuilt }}
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

</div>

<div class="card mt-3">
	<div  class="card-body">
		<h5 class="card-title">Environment</h5>
		<table class="table table-striped m-0">
			<tbody>
				<tr>
					<th>Name</th>
					<td><span class="text-muted">{{ environment.name }}</span></td>
				</tr>
				<tr>
					<th>Prod Mode</th>
					<td><span class="text-muted">{{ environment.production }}</span></td>
				</tr>
				<tr>
					<th>UX2 API URL</th>
					<td><span class="text-muted">{{ environment.apiUrl }}</span></td>
				</tr>
				<tr>
					<th>Izenda API URL</th>
					<td><span class="text-muted">{{ environment.izendaApiUrl }}</span></td>
				</tr>
				<tr>
					<th>Pendo ENV Label</th>
					<td><span class="text-muted">{{ environment.pendoEnvLabel }}</span></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!-- uncomment to test Sentry -->
<!-- <button class="btn btn-sm btn-primary ms-2" (click)="testSentry()">
	Test Sentry
</button> -->

<ng-template #runningLocally>
	<div class="card-body">
		<h5 class="card-title">Application Version</h5>
		<div class="alert alert-warning" role="alert">
			Version information is not available when running the application locally.
		</div>
	</div>
</ng-template>