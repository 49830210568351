import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuditHistoryNavigatorComponent } from "reporting/components/audit-history-navigator/audit-history-navigator.component";
import { CreateEntityContainerComponent } from "rl-common/components/create-workflow/create-entity-container.component";
import { AiModelInformationComponent } from "rl-common/components/intelligence-center/ai-model-information/ai-model-information.component";
import { DealInsightsComponent } from "rl-common/components/intelligence-center/deal-insights/deal-insights.component";
import { ExtractPdfContainerComponent } from "rl-common/components/intelligence-center/extract-pdf/extract-pdf-container.component";
import { IntelligenceCenterComponent } from "rl-common/components/intelligence-center/intelligence-center.component";
import { IntelligentExtractHintsComponent } from "rl-common/components/intelligence-center/intelligent-extract-hints/intelligent-extract-hints.components";
import { AdminAuthGuard } from "rl-common/guards/admin-auth.guard";
import { FeatureFlagGuard } from "rl-common/guards/feature-flag.guard";
import { GoEntityGuard } from "rl-common/guards/go-entity.guard";
import { UnsavedChangesGuard } from "rl-common/guards/unsaved-changes.guard";
import { DocusignGrantComponent } from "./components/docusign-grant/docusign-grant.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { GridTestComponent } from "./components/grid-test.component";
import { GridTest2Component } from "./components/grid-test2.component";
import { GridTest3Component } from "./components/grid-test3.component";
import { GridTest4Component } from "./components/grid-test4.component";
import { GridTest5Component } from "./components/grid-test5.component";
import { HomeComponent } from "./components/home.component";
import { LoginComponent } from "./components/login/login.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { SsoTokenComponent } from "./components/sso-token/sso-token.component";
import { WorkflowActionsExecuteComponent } from "./components/workflow-actions-execute/workflow-actions-execute.component";
import { LegacyRoutes } from "./utils/routing.legacy-redirects";
import { RouteSettings } from "./utils/routing.utils";

export const routes: Routes = [
	// LEGACY MATCHER ROUTES
	// TODO: APP-6058 | Remove this alias later when no longer supporting legacy URLs.
	...LegacyRoutes,
	// BASE ROUTES
	{
		path: "",
		component: HomeComponent,
		canActivate: [AdminAuthGuard]
	},
	{
		path: "login",
		component: LoginComponent
	},
	{
		path: "grid-test",
		component: GridTestComponent
	},
	{
		path: "grid-test2",
		component: GridTest2Component
	},
	{
		path: "grid-test3",
		component: GridTest3Component
	},
	{
		path: "grid-test4",
		component: GridTest4Component
	},
	{
		path: "grid-test5",
		component: GridTest5Component
	},
	{
		path: "account",
		loadChildren: () => import("../+account/account.module").then(m => m.AccountModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard]
	},
	{
		path: "style-guide",
		loadChildren: () => import("../+style-guide/style-guide.module").then(m => m.StyleGuideModule)
	},
	{
		path: "reporting/report-builder/viewer/reportpart/:id",
		loadChildren: () => import("../+izenda-export/izenda-export.module").then(m => m.IzendaExportModule)
	},
	{
		path: "data-tools",
		loadChildren: () => import("../+admin/admin.module").then(m => m.AdminModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard]
	},
	{
		path: "util",
		loadChildren: () => import("../+util/util.module").then(m => m.UtilModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard]
	},
	{
		path: "go/:entityId",
		canActivate: [GoEntityGuard],
		canActivateChild: [GoEntityGuard],
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
	},
	{
		path: "mod1",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.mod1
	},
	{
		path: "mod4",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.mod4
	},
	{
		path: "mod2",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.mod2
	},
	{
		path: "mod15",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.mod15
	},
	{
		path: "mod16",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.mod16
	},
	{
		path: "mod17",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.mod17
	},
	{
		path: "mod7",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.mod7
	},
	{
		path: "comp3",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard, FeatureFlagGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.comp3
	},
	{
		path: "comp5",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard, FeatureFlagGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.comp5
	},
	{
		path: "comp10",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard, FeatureFlagGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.comp10
	},
	{
		path: "comp14",
		loadChildren: () => import("../+mod/mod.module").then(m => m.ModModule),
		canActivate: [AdminAuthGuard, FeatureFlagGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.comp14
	},
	{
		path: "reporting",
		loadChildren: () => import("../+reporting/reporting.module").then(m => m.ReportingModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
		data: RouteSettings.reporting,
	},
	{
		path: "create",
		component: CreateEntityContainerComponent,
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
		canDeactivate: [UnsavedChangesGuard]
	},
	{
		path: "intelligence-center",
		component: IntelligenceCenterComponent,
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
		children: [
			{
				path: "",
				pathMatch: "full",
				redirectTo: "extract-pdf"
			},
			{
				path: "extract-pdf",
				component: ExtractPdfContainerComponent
			},
			{
				path: "deal-insights",
				component: DealInsightsComponent
			},
			{
				path: "intelligent-extract-hints",
				component: IntelligentExtractHintsComponent
			},
			{
				path: "ai-model-information",
				component: AiModelInformationComponent
			},
		]
	},
	{
		path: "config",
		loadChildren: () => import("../+config/config.module").then(m => m.ConfigModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
	},
	{
		path: "administration",
		loadChildren: () => import("../+company/company.module").then(m => m.CompanyModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
	},
	{
		path: "alliant",
		loadChildren: () => import("../+alliant/alliant.module").then(m => m.AlliantModule),
		canActivate: [AdminAuthGuard],
		canActivateChild: [AdminAuthGuard],
	},
	{
		path: "forgot-password",
		component: ForgotPasswordComponent
	},
	{
		path: "reset-password/:token",
		component: ResetPasswordComponent
	},
	{
		path: "sso/:token",
		component: SsoTokenComponent
	},
	{
		path: "workflow-execute",
		component: WorkflowActionsExecuteComponent
	},
	{
		path: "docusign-grant",
		component: DocusignGrantComponent
	},
	{
		path: "reporting/audit-history-navigator",
		component: AuditHistoryNavigatorComponent,
		canActivate: [FeatureFlagGuard]
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { anchorScrolling: "enabled", onSameUrlNavigation: "reload" })
	],
	providers: [],
	exports: [RouterModule]
})
export class AppRoutingModule { }
